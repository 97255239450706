import { Navigate, useLocation } from "react-router"

export const PrivateRoute =({children}:any)=>{

  const urlParams = new URLSearchParams(window.location.href)
  const ate = urlParams.get('ate') //accessToken
  const rte = urlParams.get('rte') //refreshToken
  const textDecoder = new TextDecoder()
  const at = ate
    ? textDecoder.decode(new Uint8Array(ate.split('.').map((e) => Number(e))))
    : false
  const rt = rte
    ? textDecoder.decode(new Uint8Array(rte.split('.').map((e) => Number(e))))
    : false
  if (at && rt) {
    localStorage.setItem('accessToken', at)
    localStorage.setItem('refreshToken', rt)
    localStorage.setItem('isAuth', 'true')
  }

    const location = useLocation()
    const isAuth = localStorage.getItem("isAuth")
    if(isAuth!=='true'){
        return <Navigate to='/login' state={{from:location}}></Navigate>
    }
    return children;
}